import React from 'react';
import styled from 'styled-components';
import shadow from "src/components/theme/shadows"
import Link from 'gatsby';
const StyledName = styled.h3`
  font-family: "Cinzel", serif;
  color: #3b3b3b;
  margin: 0 0 0rem 0;
  font-weight: normal;
  font-size: 2rem;
  display: block;
  @media (min-width: 778px){
    display: block;
  }
  a{
      text-decoration: none;
      color: inherit;
  }
`

const StyledHr = styled.hr`
    color: #3b3b3b;
    margin-bottom: 1rem;
    font-weight: normal;
`
const Hline = styled.div`
  height: 0;
  width: 100%;
  border-bottom: solid 1px black;
  margin: 0.5rem 0 1rem 0;
  box-shadow: ${shadow(2)};
`

const ProjectName = ({projectName, url, hline}) => {
 return (<>
    <StyledName><a href={url}>
    {projectName}
    </a>
    </StyledName>
    {hline ? 
    <Hline/> : null }
 </>)
}

export default ProjectName