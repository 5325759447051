import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import shadow from "components/theme/shadows"

const StyledCover = styled.div`
  // flex-shrink: 0;
  // border-radius: 8px;
  // background-color: #f3f3f3;
  // // padding-top: 100%;
  // position: relative;
  // border-radius: 8px;
  // overflow: hidden;
  // // @media screen and(min-width:578px){
  //   width: 20rem;
  //   height: 20rem;
  //   position: relative;
  // // }
  // max-width: 100%;
  flex-shrink:0;
  flex-basis: 45%;
  `

const StyledImage = styled(Img)`
    // border-radius: 4px;
    // height: 100%;
    // width: 100%;
    // object-fit: cover;
    // object-fit: contain;
    // // @media screen and (max-width: 576px){
    //   top: 0;
    //   left: 0;

    //   position: absolute!important;
    //   height: 100%!important;
    //   width: 100%!important;
    //   object-fit: contain!important;
    // // }
    @media (min-width: 1024px){
      height: 0;
      padding-top: 100%;
      flex-shrink: 0;
    }
`

const FWImg = styled(Img)`
    width: 100%;
    border-radius: 8px;

  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
`

const ProjectCover = ({ fluid }) => {
  return (
    <StyledCover>
      {/* <Img fixed={fixed}/> */}
      {/* <StyledImage placeholderStyle={{
  opacity:  0,
}} fixed={fixed}  imgStyle={{ width: '100%', objectFit: 'cover' }} />  */}
    <FWImg fluid={fluid}/>
    </StyledCover>
  )
}

export default ProjectCover
