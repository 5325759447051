import React from "react"
import theme from "src/components/theme/theme"
import shadow from "src/components/theme/shadows"
import styled from "styled-components"
import ProjectName from "src/components/projects/project_name"
import ProjectDetails from "src/components/projects/project_details"
import ProjectCover from "src/components/projects/project_cover"

import { Link } from "gatsby"


// const StyledOverview = styled(Link)`
const StyledOverview = styled.a`
height: 100%;
flex-grow:1;
// margin-top: 2rem;
text-decoration: none;
  display: flex;
  flex-direction: column;
  @media (min-width: 1024px){
    flex-direction:row;
  }
  // display: block;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: ${shadow(16)};
  background-color: #f3f3f3;
  justify-content: space-between;
  z-index: 10;
  
`

const StyledOverviewContents = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  justify-content: space-between;
  @media (min-width: 1024px){
    text-align: right;
    padding-right: 1rem;
      // flex-direction: row;
  // flex-direction: column;
}
`

const Hline = styled.div`
  height: 0;
  width: 100%;
  border-bottom: solid 1px black;
  margin: 0.5rem 0 1rem 0;
  box-shadow: ${shadow(2)};
`

const ProjectOverview = ({ projectName, url, projectLocation,projectName2, projectLocation2, cover }) => {
  return (
    <StyledOverview href={url}>
      {/* <div> */}

      <StyledOverviewContents>
        <div>

      <ProjectName projectName={projectName} url={url} />
      <ProjectName projectName={projectName2} url={url} />
    </div>
      <Hline/>
      {/* </div> */}
        <ProjectDetails bottom={false} projectLocation={projectLocation}/>
        <ProjectDetails bottom={true} projectLocation={projectLocation2}/>

      </StyledOverviewContents>
        <ProjectCover fluid={cover}/>
    </StyledOverview>
  )
}


export default ProjectOverview