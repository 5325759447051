import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import Fade from 'react-reveal/Fade'
import { Container, Row, Col } from 'react-grid-system'
import { Helmet } from 'react-helmet'

import SEO from 'src/components/seo'
import styled from 'styled-components'
import ProjectOverview from 'src/components//projects/project_overview'
import Layout from 'src/components/layout/layout'

const PhotosWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const PhotoWrapper = styled.div`
  margin: .5rem;
  border-radius: 8px;
  overflow: hidden;
  flex-grow: 1;
  
  img{
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`

const LinkWrapper = styled.div`
  margin-top: 1rem;
`

const AllPhotos = styled.div`
  
`
const FadeWrapper = styled.div`
  height: calc(100% - 2rem);
  // padding-top: 2rem;
  @media (min-width: 1024) {
    padding-top: 0;
    height: calc(100% - 4rem);
  }
`
const OverviewWrap = styled.div`
  height: calc(100% - 2rem);
  padding: 1rem;
  @media (min-width: 1024) {
    height: calc(100% - 4rem);
  }
`
const StyledFade = styled(Fade)``

const StyledCol = styled.div`
order: ${props => props.order};
box-sizing: border-box;
    min-height: 1px;
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
    margin-left: 0%;
    right: auto;
    left: auto;
}
@media (min-width: 778px){  
  box-sizing: border-box;
  min-height: 1px;
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
  width: 50%;
  flex: 0 0 50%;
  max-width: 50%;
  margin-left: 0%;
  right: auto;
  left: auto;
  }
`

const RoyalCoinPage = ( ) => {
  const data = useStaticQuery(graphql`
    query CoinQuery {
      allFile(filter: { relativeDirectory: { eq: "coin" } }) {
        nodes {
          dir
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)
  console.log(data,"DATA")
  const fifty1 = data.allFile.nodes[0].childImageSharp.fluid
  const fifty2 = data.allFile.nodes[1].childImageSharp.fluid
  const coins = data.allFile.nodes[2].childImageSharp.fluid
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setLoaded(true)
  }, [])
  return !loaded ? (
    <Layout />
  ) : (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Martin Jennings - Royal coin</title>
        <meta
          name="description"
          content="I am proud to have designed and modelled the image of HM King Charles III for the new national coinage."
        />
      </Helmet>
      <Container>
        <Row>
          <OverviewWrap>
            <p>I have designed and modelled the obverse face of the new national coinage in response to a commission from the Royal Mint.</p>

<p>This features King Charles III's head in profile. I modelled this first at a larger scale before it was digitally scanned and reduced. In collaboration with the team at the Royal Mint I then designed the inscriptions which encircle the effigy. To date sovereigns, crowns and 50 pence pieces have been released, with the other denominations to follow in 2023.</p> 
<p>
In collaboration with the Royal Mint design team, I have also modelled a crowned effigy for a special coronation edition
</p>
<Row>

  <PhotoWrapper><Img fluid={fifty2}/></PhotoWrapper>
  <PhotoWrapper><Img fluid={coins}/></PhotoWrapper>

</Row>
<Row>

  <PhotoWrapper><Img fluid={fifty1}/></PhotoWrapper>

</Row>
<LinkWrapper><a href="https://www.royalmint.com/aboutus/press-centre/the-royal-mint-unveils-official-coin-effigy-of-his-majesty-king-charles-iii/">The Royal Mint unveils official coin effigy of his Majesty King Charles III</a></LinkWrapper>
<LinkWrapper><a href="https://www.bbc.co.uk/news/business-63888781">First King Charles 50p coins enter circulation - BBC</a></LinkWrapper>

<LinkWrapper><a href="https://www.theguardian.com/uk-news/2022/dec/08/first-coinage-featuring-king-charles-iii-released">First coinage featuring King Charles III released - Guardian</a></LinkWrapper>

<LinkWrapper><a href="https://www.theguardian.com/commentisfree/2023/apr/13/king-charles-head-royal-mint-portrait-ears">What modelling King Charles’s head taught me about very public portraits – and sovereignty itself - Guardian</a></LinkWrapper>

<LinkWrapper><a href="https://www.royalmint.com/shop/monarch/charles-iii/coronation/the-crowned-coinage-portrait-of-his-majesty-king-charles-iii/?utm_campaign=2023-com-king-charles-coronation&utm_medium=email&_hsmi=255480634&_hsenc=p2ANqtz-_3LhLFuA_XJlbwS-VO8n2Pj4W0ODepZiTp-9LZPd7HhnbfkYYDo5TgXUWNVFJhdJq5qvjKMs1kwg_bB-xwOUHegAouzA&utm_content=255480634&utm_source=hs_email">The Crowned Coinage Portrait of
His Majesty King Charles III</a></LinkWrapper>



</OverviewWrap>
{/* <AllPhotos>
  <PhotoWrapper><Img fluid={model}/></PhotoWrapper>
</AllPhotos> */}
        </Row>
      </Container>
    </Layout>
  )
}

export default RoyalCoinPage
