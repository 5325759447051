import React from "react"
import styled from "styled-components"

const StyledDetails = styled.div`
  // padding: 0 2rem 0 0;
  flex-grow: ${props=>props.bottom? "1" : "0"};
`
const StyledParagraph = styled.p`
  font-family: "Old Standard TT", serif;
  font-family: "Cormorant", serif;
  color: #3b3b3b;
  margin-bottom: ${props=>props.bottom? "0.5em" : "0"};
`

const ProjectDetails = ({ projectLocation, bottom }) => {
  return (
      <StyledDetails bottom={bottom}>
      <StyledParagraph bottom={bottom}>{projectLocation}</StyledParagraph>
    </StyledDetails>
  )
}

export default ProjectDetails
